import React from 'react'

const WildFernet = () => {

  const wildParrafos = "text-white w-[70%] text-center font-bahnschrift md:text-[1.5rem] md:w-[50%]"

  return (
    <section id='quePardalis' className='w-[100vw] flex justify-center z-[-1]'>
      <section className='bg-wildMobile-pattern md:bg-none bg-cover md:absolute md:z-[-1] flex justify-center items-center flex-col h-[100vh]'>
        <h2 data-aos="fade-up" data-aos-duration="500" className='font-cristone text-center text-[3rem] md:text-[4rem] text-white md:w-[25%]'>Wild & Fernet</h2>
        <p data-aos="fade-up" data-aos-duration="500" className={`${wildParrafos}`}>Pardalis nace en el corazón de El Palmar y se extiende al resto del mundo con fiereza.</p>
        <p data-aos="fade-up" data-aos-duration="500" className={`${wildParrafos}`}>Con salvajismo y elegancia, Pardalis toma la fuerza del ocelote con la delicadeza de las hierbas naturales de su entorno y se convierte en una bebida única que transforma la experiencia de tomar fernet.</p>
      </section>
      <div className='none md:relative md:z-[-2] md:h-[100vh] md:w-[50vw] md:bg-wild1-pattern md:bg-center md:bg-cover'></div>
      <div className='none md:relative md:z-[-2] md:h-[100vh] md:w-[50vw] md:bg-wild2-pattern md:bg-center md:bg-cover'></div>
    </section>
  )
}

export default WildFernet