import React from 'react'
import pardalisRed from '../assets/imgs/pardalisRed.webp'
import { IoIosMenu } from "react-icons/io";
import { IoIosClose } from "react-icons/io";
import {Link} from 'react-scroll'

const Navbar = () => {

    const openMenu = (resp) =>{
        if(resp){
            let list = document.querySelector('.navList');
            let burger = document.querySelector('.burger');
            let close = document.querySelector('.close');
            list.classList.add('opacity-100');
            list.classList.add('top-[79px]')
            burger.classList.add('opacity-0');
            close.classList.add('opacity-100');
            close.classList.add('z-[3]')
        } else {
            let list = document.querySelector('.navList');
            let burger = document.querySelector('.burger');
            let close = document.querySelector('.close');
            list.classList.remove('opacity-100');
            list.classList.remove('top-[79px]')
            burger.classList.remove('opacity-0');
            close.classList.remove('opacity-100');
            close.classList.remove('z-[3]');
        }
    }

    const liStyle = "m-4 cursor-pointer hover:text-[#FED0C6] transition-all ease-in duration-300 "

    // lg:bg-gradient-to-r from-transparent to-[#962125] prueba desktop

  return (
    <nav className='p-5 z-[3] fixed top-0 bg-[#160506]/90 w-[100vw]  md:flex md:justify-between md:items-center'>
        <div className='flex items-center justify-between relative z-[3] w-full h-full'>
            <div className='flex justify-center md:justify-between w-full relative z-[3]'>
                <img className='h-[2.5rem]' src={pardalisRed} alt="" />
                <div className='absolute flex w-[100%] h-[100%] justify-end items-center'>
                    <button className='burger  transition-all ease-in duration-500 cursor-pointer md:opacity-0 font-bold mx-2 text-[#962125]  text-[2.5rem] z-[3]' onClick={() => {openMenu(true)}}><IoIosMenu /></button>
                    <button className='close  transition-all ease-in duration-500 cursor-pointer opacity-0 font-bold ml-2 text-[#962125] text-[3rem] absolute ' onClick={() => {openMenu(false)}}><IoIosClose /></button>
                </div>
            </div>
        </div>
        <ul className='navList font-blatan text-2xl items-center uppercase md:flex md:items-center z-[4] md:z-auto md:static fixed bg-[#160506]/90 md:bg-transparent w-[100%] h-[35vh] md:h-full left-0 md:w-auto md:py-0 py-4 md:ml-10 pl-7 md:opacity-100 opacity-0 top-[-400px] transition-all ease-in-out duration-500 text-[#962125]'>
            <li className={liStyle}><Link   to="quePardalis" 
                                            spy={true} 
                                            smooth={true} 
                                            offset={-50} 
                                            duration={500}
                                            onClick={() => {openMenu(false)}}>Qué</Link></li>
            <li className={liStyle}><Link   to="comoPardalis" 
                                            spy={true} 
                                            smooth={true} 
                                            offset={-0} 
                                            duration={500} 
                                            onClick={() => {openMenu(false)}}>Cómo</Link></li>
            <li className={liStyle}><Link   to="dondePardalis" 
                                            spy={true} 
                                            smooth={true} 
                                            offset={0} 
                                            duration={500} 
                                            onClick={() => {openMenu(false)}}>Dónde</Link></li>
                                            <li className={liStyle}><Link   to="contactoPardalis" 
                                            spy={true} 
                                            smooth={true} 
                                            offset={0} 
                                            duration={500} 
                                            onClick={() => {openMenu(false)}}>Contacto</Link></li>
        </ul>
    </nav>
    
  )
}

export default Navbar