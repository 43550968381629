import React from 'react'
import fernet from '../assets/imgs/fernet.png'
import pintadaGris from '../assets/imgs/pintadaGris.png'
import pintadaRoja from '../assets/imgs/pintadaRoja.png'

const WildFernetFermelo = () => {

    const wildParrafos = "text-white w-[80%] text-center font-bahnschrift md:text-[1rem] 2xl:text-[1.2rem]";

  return (
    <section id='comoPardalis' className='flex flex-col lg:flex-row justify-center items-center relative z-[-2] md:z-[-1] md:w-[100vw] lg:h-[100vh]'>
        <div className='absolute z-[0] flex flex-col justify-around'>
            <img src={fernet} alt="" className='h-[33rem] rotate-[12deg] lg:h-[40rem] 2xl:h-[55rem]'/>
            <img data-aos="fade-left" data-aos-duration="500" src={pintadaRoja} alt="" className='h-[2rem] lg:h-[2.5rem] absolute top-[8rem] right-8 lg:right-18 2xl:right-24'/>
            <img data-aos="fade-right" data-aos-duration="500" src={pintadaGris} alt="" className='h-[5rem] lg:h-[6rem] absolute bottom-0 lg:right-10'/>
        </div>
        <article className='bg-vasosFernet-pattern h-[100vh] md:h-[100vh] lg:w-[50vw] w-full bg-center bg-cover md:bg-top flex flex-col items-center justify-center lg:justify-start'>
            <div className='mb-[25rem] flex flex-col items-center lg:mt-40'>
                <h3 className='font-cristone text-[#962125] text-4xl mb-4 lg:text-[3rem] 2xl:text-[3.5rem] lg:mb-4'>Wild Fernet</h3>
                <p className={`${wildParrafos} md:w-[60%]`}>Clásica fórmula del fernet con cola.</p>
                <p className={`${wildParrafos} md:w-[60%]`}>Ideal para esas noches en buenas compañías.</p>
            </div>
        </article>
        <article className='bg-fermelo-pattern h-[100vh] md:h-[100vh] lg:w-[50vw] w-full bg-cover bg-center md:bg-top flex flex-col justify-end lg:justify-start  items-center'>
          <div className='flex flex-col items-center bg-gradient-to-b from-transparent to-black w-full lg:mb-[25rem] lg:bg-none lg:mt-40'>
            <h3 className='font-cristone text-black md:text-white lg:text-[#962125] text-4xl pb-2 lg:text-[3rem]  2xl:text-[3.5rem] lg:mb-4'>Fermelo</h3>
            <p className={`${wildParrafos} w-[55%] pb-2 lg:pb-0 md:w-[50%]`}>Fernet con pomelo.</p>
            <p className={`${wildParrafos} w-[55%] pb-2 lg:pb-0 md:w-[50%]`}>Otra forma de tomar Pardalis y disfrutar de un excelente trago.</p>
            <p className={`${wildParrafos} w-[55%] pb-6 lg:pb-0 md:w-[50%]`}>Ideal para tomarlo al borde de la pile o en la playa bajo el sol.</p>
          </div>
        </article>
    </section>
  )
}

export default WildFernetFermelo