
import './App.css';
import Contacto from './components/Contacto';
import Footer from './components/Footer';
import Hierbas from './components/Hierbas';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Venta from './components/Venta';
import WildFernet from './components/WildFernet';
import WildFernetFermelo from './components/WildFernetFermelo';

function App() {


  return (
    <main className='overflow-hidden'>
      <Navbar/>
      <div className=''>
        <Home/>
        <WildFernet/>
        <WildFernetFermelo/>
        <Hierbas/>
        <Venta/>
        <Contacto/>
        <Footer/>
      </div>
    </main>
  );
}

export default App;
