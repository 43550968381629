import React from 'react'

const Footer = () => {
  return (
    <footer className='w-[100%] h-[2rem] text-[#962125] flex justify-center items-center text-[.7rem] gap-4 font-blatan uppercase cursor-pointer transition-all ease-in duration-500 md:text-[.9rem]'>
        <a className='hover:text-[#FED0C6] transition-all ease-in duration-300' href='https://api.whatsapp.com/send?phone=3447405798' target='_blank' rel="noreferrer">Diseño Nicolás Fernandez</a>
        <a className='hover:text-[#FED0C6] transition-all ease-in duration-300' href='https://www.linkedin.com/in/santiago-paiz/' target='_blank' rel="noreferrer">Desarrollo Santiago Paiz</a>
    </footer>
  )
}

export default Footer