import React from 'react'
import { CiInstagram } from "react-icons/ci";

const Contacto = () => {
  return (
    <section id="contactoPardalis" className='h-[90vh] flex flex-col justify-center items-center bg-contactoMobile-pattern bg-cover bg-right'>
        <a href="https://www.instagram.com/pardalisferneteria/" className='text-white font-blatan uppercase flex flex-col items-center gap-4 md:text-[1.4rem] tracking-[.4rem]' target='_blank' rel="noreferrer"><CiInstagram className='text-white text-[5rem]'/>pardalisferneteria</a>
        <a href="https://api.whatsapp.com/send?phone=3447415406&text=Quiero%20revender"  target='_blank' rel="noreferrer" data-aos="fade-up"  data-aos-duration="500" className='text-white mt-20 bg-black/80 p-4 font-bahnschrift pointer uppercase border-b-2 border-[#962125c5] hover:bg-[#962125] transition-all duration-300'>Quiero Pardalis en mi hogar/comercio</a>
    </section>
  )
}

export default Contacto